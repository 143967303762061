import '@styles/react/libs/flatpickr/flatpickr.scss'
import Cleave from 'cleave.js/react'
import Flatpickr from 'react-flatpickr'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {Button, Form, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap'
import {entrySgkWorkerDeclarationApi, toggleSgkSingularEntryPopupClose} from '../store'
// ** Configs
import flatpickrConfig from "@configs/flatpickrConfig"
// ** Utils
import {sendDateFormat} from "@utils"
import {useEffect, useState} from 'react'
import axios from 'axios'
import { getModuleIdsByCompanyGroupId } from '../../../../../auth/utils'
import { addWorkerDeclaration } from '../../Police/store'
import toast from 'react-hot-toast'
// import toast from "react-hot-toast"


const SidebarSingularSgkEntry = ({open}) => {
    const store = useSelector(state => state.sgkWorkerDeclaration)
    const {selectedCompanyGroup, userData } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [postData, setPostData] = useState(null)
    const [companyIsHotel, setCompanyIsHotel] = useState(false)
    const [continuePopup, setContinuePopup] = useState(false)
    const [declarationType, setDeclarationType] = useState(null)

    const moduleIds = getModuleIdsByCompanyGroupId(userData, selectedCompanyGroup)
    const hasPermission = moduleIds.includes(4)

    const continuePopupClose = () => {
        setContinuePopup(false)
    }

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors}
    } = useForm({
        defaultValues: {
            identificationNumber: "",
            workStartDate: "",
            insuranceLever: "",
            disabilityCode: "",
            exLaw: "",
            educationStatus: "",
            graduationYear: "",
            graduationSection: "",
            workPartially: "",
            workPartiallyDays: "",
            cgbis: "",
            jobName: "",
            dutyCode: "",
            name: "",
            surname: "",
            companyId: "",
            residenceAddress: null,
            securityKey: null
        }
    })
    const popupClose = () => {
        dispatch(toggleSgkSingularEntryPopupClose(false))
        reset()
    }

    const onSubmit = async (data) => {
        setPostData(data)
        const response = await axios.post('/api/CompanyWorker/sgkSingularEntryCheck', {
            workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null
        })

        if (response.data) {
            setDeclarationType('sgk')
            setContinuePopup(true)
            popupClose()
        } else {
            dispatch(
                entrySgkWorkerDeclarationApi({
                    identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                    workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                    insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                    disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                    exLaw: data.exLaw ? data.exLaw.value : "",
                    educationStatus: data.educationStatus ? data.educationStatus.value : "",
                    graduationYear: data.graduationYear ? data.graduationYear : 0,
                    graduationSection: data.graduationSection,
                    workPartially: data.workPartially ? data.workPartially.value : "",
                    workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                    cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                    jobName: data.jobName ? data.jobName.value.toString() : "",
                    dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                    name: data.name,
                    surname: data.surname,
                    companyId: data.company ? data.company.value : 0
                })
            )
        }
    }

    const onSubmitWithPoliceDeclaration = async (data) => {
        console.log(data)
        setPostData(data)
        const responsePolicePasswordCheck = await axios.get('/api/CompanyServiceAccess/CompanyHasPolicePassword', {
            params: {companyId: data.company.value}
        })
        if (responsePolicePasswordCheck.data.hasPolicePassword === false) {
            toast.error("Emniyet Bildirimi Yapabilmek İçin Şifre Tanımlanmış Olmalıdır.")
        } else if (responsePolicePasswordCheck.data.hotelCount > 0) {
            toast.error("Emniyet Bildirimi Otel Bildirimlerinde Kullanılamaz.")
        } else {
            const responseSgkCheck = await axios.post('/api/CompanyWorker/sgkSingularEntryCheck', {
                workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null
            })
            if (responseSgkCheck.data) {
                setDeclarationType('police')
                setContinuePopup(true)
                popupClose()
            } else {
                dispatch(
                    addWorkerDeclaration({
                        identificationNumber: data.identificationNumber ?? "",
                        residenceAddress: data.residenceAddress ?? "",
                        employmentDate: sendDateFormat(data.workStartDate) ?? null,
                        title: data.jobName.label ?? "",
                        Type: 2, //police
                        SyncStatus: 5,
                        SecurityKey : data.securityKey !== null ? data.securityKey : "",
                        IsPoliceHotelWorkerDeclarationEmploye: data.company?.isPoliceHotelWorkerDeclarationEmploye,
                        CompanyId: data.company !== null ? data.company.value : 0
                    }),

                    entrySgkWorkerDeclarationApi({
                        identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                        workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                        insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                        disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                        exLaw: data.exLaw ? data.exLaw.value : "",
                        educationStatus: data.educationStatus ? data.educationStatus.value : "",
                        graduationYear: data.graduationYear ? data.graduationYear : 0,
                        graduationSection: data.graduationSection,
                        workPartially: data.workPartially ? data.workPartially.value : "",
                        workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                        cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                        jobName: data.jobName ? data.jobName.value.toString() : "",
                        dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                        name: data.name,
                        surname: data.surname,
                        companyId: data.company ? data.company.value : 0
                    }),

                    toast.success("Emniyet bildirimi sürecini, emniyet bildirimi ekranından takip edebilirsiniz.")
                )
            }
        }

    }

    const onSubmitWithGendermeDeclaration = async (data) => {
        console.log(data)
        setPostData(data)
        const responseGendermePasswordCheck = await axios.get('/api/CompanyServiceAccess/CompanyHasGendermePassword', {
            params: {companyId: data.company.value}
        })
        if (responseGendermePasswordCheck.data.hasGendermePassword === false) {
            toast.error("Jandarma Bildirimi Yapabilmek İçin Şifre Tanımlanmış Olmalıdır.")
        } else {
            const responseSgkCheck = await axios.post('/api/CompanyWorker/sgkSingularEntryCheck', {
                workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null
            })
            if (responseSgkCheck.data) {
                setDeclarationType('genderme')
                setContinuePopup(true)
                popupClose()
            } else {
                dispatch(
                    addWorkerDeclaration({
                        identificationNumber: data.identificationNumber ?? "",
                        residenceAddress: data.residenceAddress ?? "",
                        employmentDate: sendDateFormat(data.workStartDate) ?? null,
                        title: data.jobName.label ?? "",
                        Type: 1, //genderme
                        SyncStatus: 5,
                        SecurityKey : data.securityKey !== null ? data.securityKey : "",
                        IsPoliceHotelWorkerDeclarationEmploye: data.company?.isPoliceHotelWorkerDeclarationEmploye,
                        CompanyId: data.company !== null ? data.company.value : 0
                    }),

                    entrySgkWorkerDeclarationApi({
                        identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                        workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                        insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                        disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                        exLaw: data.exLaw ? data.exLaw.value : "",
                        educationStatus: data.educationStatus ? data.educationStatus.value : "",
                        graduationYear: data.graduationYear ? data.graduationYear : 0,
                        graduationSection: data.graduationSection,
                        workPartially: data.workPartially ? data.workPartially.value : "",
                        workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                        cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                        jobName: data.jobName ? data.jobName.value.toString() : "",
                        dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                        name: data.name,
                        surname: data.surname,
                        companyId: data.company ? data.company.value : 0
                    }),

                    toast.success("Jandarma sürecini, jandarma bildirimi ekranından takip edebilirsiniz.")
                )
            }
        }

    }


    const continueOnSubmit = () => {
        const data = postData
        dispatch(
            entrySgkWorkerDeclarationApi({
                identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                exLaw: data.exLaw ? data.exLaw.value : "",
                educationStatus: data.educationStatus ? data.educationStatus.value : "",
                graduationYear: data.graduationYear ? data.graduationYear : 0,
                graduationSection: data.graduationSection,
                workPartially: data.workPartially ? data.workPartially.value : "",
                workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                jobName: data.jobName ? data.jobName.value.toString() : "",
                dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                name: data.name,
                surname: data.surname,
                companyId: data.company ? data.company.value : 0
            })
        )
        setContinuePopup(false)
    }

    const continueOnSubmitWithPoliceDeclaration = () => {
        const data = postData
        dispatch(
            addWorkerDeclaration({
                identificationNumber: data.identificationNumber ?? "",
                residenceAddress: data.residenceAddress ?? "",
                employmentDate: sendDateFormat(data.workStartDate) ?? null,
                title: data.jobName.label ?? "",
                Type: 2, //police
                SyncStatus: 5,
                SecurityKey : data.securityKey !== null ? data.securityKey : "",
                IsPoliceHotelWorkerDeclarationEmploye: data.company?.isPoliceHotelWorkerDeclarationEmploye,
                CompanyId: data.company !== null ? data.company.value : 0
            }),

            entrySgkWorkerDeclarationApi({
                identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                exLaw: data.exLaw ? data.exLaw.value : "",
                educationStatus: data.educationStatus ? data.educationStatus.value : "",
                graduationYear: data.graduationYear ? data.graduationYear : 0,
                graduationSection: data.graduationSection,
                workPartially: data.workPartially ? data.workPartially.value : "",
                workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                jobName: data.jobName ? data.jobName.value.toString() : "",
                dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                name: data.name,
                surname: data.surname,
                companyId: data.company ? data.company.value : 0,
                schedulerType: 11
            })
        )
        setContinuePopup(false)
    }

    const continueOnSubmitWithGendermeDeclaration = () => {
        const data = postData
        dispatch(
            addWorkerDeclaration({
                identificationNumber: data.identificationNumber ?? "",
                residenceAddress: data.residenceAddress ?? "",
                employmentDate: sendDateFormat(data.workStartDate) ?? null,
                title: data.jobName.label ?? "",
                Type: 1, //genderme
                SyncStatus: 5,
                SecurityKey : data.securityKey !== null ? data.securityKey : "",
                IsPoliceHotelWorkerDeclarationEmploye: data.company?.isPoliceHotelWorkerDeclarationEmploye,
                CompanyId: data.company !== null ? data.company.value : 0
            }),

            entrySgkWorkerDeclarationApi({
                identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                exLaw: data.exLaw ? data.exLaw.value : "",
                educationStatus: data.educationStatus ? data.educationStatus.value : "",
                graduationYear: data.graduationYear ? data.graduationYear : 0,
                graduationSection: data.graduationSection,
                workPartially: data.workPartially ? data.workPartially.value : "",
                workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                jobName: data.jobName ? data.jobName.value.toString() : "",
                dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                name: data.name,
                surname: data.surname,
                companyId: data.company ? data.company.value : 0,
                schedulerType: 11
            })
        )
        setContinuePopup(false)
    }

    useEffect(() => {
        reset()
    }, [store.singularEntryPopupStatus])

    const fetchOptions = async (input) => {
        if (!input) {
            setOptions([])
            return
        }

        try {
            const response = await axios.get(`/api/CommonValue/Job`, {
                params: {Query: input}
            })

            const fetchedOptions = response.data.map((item) => ({
                value: item.value,
                label: `${item.value} - ${item.name}`
            }))

            setOptions(fetchedOptions)
        } catch (error) {
            console.error('Error fetching options:', error)
        }
    }

    const handleInputChange = (newValue) => {
        setInputValue(newValue)
        fetchOptions(newValue)
    }
    const residenceAddressValue = watch('residenceAddress')
    const optionsTcKimlikNo = {blocks: [11], numericOnly: true}
    const optionsgraduationYear = {blocks: [4], numericOnly: true}
    const optionsworkPartiallyDays = {blocks: [2], numericOnly: true}

    const handleContinue = () => {
        if (declarationType === 'police' && hasPermission && residenceAddressValue) {
            continueOnSubmitWithPoliceDeclaration()
        } else if (declarationType === 'genderme' && hasPermission && residenceAddressValue) {
            continueOnSubmitWithGendermeDeclaration()
        } else {
            continueOnSubmit()
        }
        setContinuePopup(false)
    }

    return (
        <>
            <Modal isOpen={open} toggle={popupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>SGK</h1>
                        <p>Tekil Giriş Bildirimi</p>
                    </div>

                    <Form style={{}} onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-1'>
                            <Label className='form-label' for='company'>
                                Sicil <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='company'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.companyList}
                                        className='react-select'
                                        classNamePrefix='select'
                                        {...field}
                                        onChange={e => {
                                            field.onChange(e)
                                            if (e.isPoliceHotelWorkerDeclarationEmploye) {
                                                setCompanyIsHotel(true)
                                            } else {
                                                setCompanyIsHotel(false)
                                            }
                                        }}
                                    />

                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='identificationNumber'>
                                T.C. Kimlik No <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='identificationNumber'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='identificationNumber' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsTcKimlikNo}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='workStartDate'>
                                Giriş Tarihi <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='workStartDate'
                                control={control}
                                render={({field}) => (
                                    <Flatpickr className='form-control' options={flatpickrConfig}
                                               id='workStartDate' {...field} />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='insuranceLever'>
                                Sigorta Kolu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='insuranceLever'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.InsuranceTypesData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='disabilityCode'>
                                Engellilik Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='disabilityCode'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={[{label: 'Evet', value: 'E'}, {label: 'Hayır', value: 'H'}]}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='exLaw'>
                                Eski Hükümlü <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='exLaw'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={[{label: 'Evet', value: 'E'}, {label: 'Hayır', value: 'H'}]}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='educationStatus'>
                                Öğrenim Durumu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='educationStatus'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.sgkEducationData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='graduationYear'>
                                Mezuniyet Yılı
                            </Label>
                            <Controller
                                name='graduationYear'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='graduationYear' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsgraduationYear}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='graduationSection'>
                                Mezuniyet Bölümü
                            </Label>
                            <Controller
                                name='graduationSection'
                                control={control}
                                render={({field}) => (
                                    <Input id='graduationSection' placeholder=''
                                           invalid={errors.name && true} {...field} />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='workPartially'>
                                Kısmi Süreli Çalışıyor Mu? <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='workPartially'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={[{value: 'Evet', label: 'Evet'}, {value: 'Hayır', label: 'Hayır'}]}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='workPartiallyDays'>
                                Çalışılacak Gün Sayısı <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='workPartiallyDays'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='workPartiallyDays' placeholder=''
                                            invalid={errors.name && true} {...field}
                                            options={optionsworkPartiallyDays}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='educationStatus'>
                                CGBIS İş Kolu<span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='cgbis'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.CSGBBusinessDepartmentsData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='jobName'>
                                Sigortalı Meslek Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='jobName'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        className='react-select'
                                        classNamePrefix='select'
                                        options={options}
                                        onInputChange={handleInputChange}
                                        inputValue={inputValue}
                                        placeholder="Aramak için yazın..."
                                        isClearable
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='dutyCode'>
                                Görev Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='dutyCode'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.DutyCodesData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='name'>
                                Ad <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='name'
                                control={control}
                                render={({field}) => (
                                    <Input id='name' placeholder='' invalid={errors.name && true} {...field} />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='surname'>
                                Soyad <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='surname'
                                control={control}
                                render={({field}) => (
                                    <Input id='surname' placeholder='' invalid={errors.name && true} {...field} />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='residenceAddress'>
                                Adres <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='residenceAddress'
                                control={control}
                                disabled={!hasPermission}
                                render={({field}) => (
                                    <Input id='residenceAddress' placeholder='' type='textarea' rows="3" invalid={errors.residenceAddress && true} {...field} />
                                )}
                            />
                        </div>

                        {companyIsHotel &&
                        <div className='mb-1'>
                        <Label className='form-label' for='securityKey'>
                            Güvenlik Kodu <span className='text-danger'>*</span>
                        </Label>
                        <Controller
                            name='securityKey'
                            control={control}
                            render={({field}) => (
                                <Input id='securityKey' placeholder='' invalid={errors.residenceAddress && true} disabled={!hasPermission || !residenceAddressValue } {...field} />
                            )}
                        />
                    </div>}
                    <div className='d-flex flex-column gap-1'>
                        <div className='d-flex flex-column flex-md-row gap-1'>
                            <Button type='submit' color='primary' className='flex-fill text-nowrap'>
                                Kaydet
                            </Button>
                            <Button
                                type='button'
                                color='primary'
                                className='flex-fill text-nowrap'
                                onClick={handleSubmit(onSubmitWithPoliceDeclaration)}
                                disabled={!hasPermission || !residenceAddressValue}
                            >
                                Kaydet ve Emniyet Bildirimi Yap
                            </Button>
                            <Button
                                type='button'
                                color='primary'
                                className='flex-fill text-nowrap'
                                onClick={handleSubmit(onSubmitWithGendermeDeclaration)}
                                disabled={!hasPermission || !residenceAddressValue}
                            >
                                Kaydet ve Jandarma Bildirimi Yap
                            </Button>
                        </div>
                        <div className='d-flex justify-content-end mt-1'>
                            <Button type='reset' color='secondary' outline onClick={popupClose}>
                                İptal
                            </Button>
                        </div>
                    </div>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={continuePopup} toggle={continuePopupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={continuePopupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Dikkat</h1>
                        <p>İşe Giriş İşleminiz Cezaya Tabii Tutulacaktır. Devam Etmek İstiyor Musunuz?</p>
                    </div>
                    <Button type='submit' className='me-1' color='primary' onClick={handleContinue}>
                        Devam Et
                    </Button>
                    <Button type='reset' color='secondary' outline onClick={continuePopupClose}>
                        İptal
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}
export default SidebarSingularSgkEntry